.formText {
    color: white;
}

.formLabel {
    color: white;
}

.cardTitle, h5 {
    color: #98e5ed;
}